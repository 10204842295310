import React from 'react';
import { FooterText, Mail, Phone } from '../design/SharedComponents';
import { Footer as IFooter } from '../content';
import styled from 'styled-components';
import { seed } from '../content/seed';

const FooterSC = styled.div`
  background-color: ${(props) => props.theme.color.blue};
  height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;

  margin-top: auto;
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 33%;
`;

interface Props {
  tax: string;
  company: string;
  author: string;
}

const Footer: React.FC<IFooter> = ({ tax, company, author }) => {
  return (
    <FooterSC>
      <Column>
        <div>
          {tax.map((item) => (
            <FooterText key={item}>{item}</FooterText>
          ))}
        </div>
      </Column>
      <Column>
        <FooterText>{`${new Date().getFullYear()} © ${company}`}</FooterText>
      </Column>
      <Column>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Phone
            value={seed.contact.phone}
            style={{
              fontSize: '14px',
              color: '#fff',
              fontWeight: 'normal',
              textDecorationThickness: '1px',
            }}
          />
          <Mail
            value={seed.contact.email}
            style={{
              marginTop: '3px',
              fontSize: '14px',
              color: '#fff',
              fontWeight: 'normal',
              textDecorationThickness: '1px',
            }}
          />
        </div>
      </Column>
    </FooterSC>
  );
};

export default Footer;
