import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { H3, Text } from '../design/SharedComponents';
import SiteTemplate from '../components/pagesTemplate/SiteTemplate';

const MainSC = styled.main`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const NotFoundPage = () => {
  return (
    <SiteTemplate>
      <MainSC>
        <title>Not found</title>
        <H3>Stránka nebola nájdená</H3>
        <Text>
          Prepáčte{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>
          , ale nepodarilo sa nám nájsť hľadanú stránku.
          <br />
          <Link to="/">Prejsť na hlavnú stránku</Link>.
        </Text>
      </MainSC>
    </SiteTemplate>
  );
};

export default NotFoundPage;
