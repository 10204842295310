import { createGlobalStyle } from 'styled-components';

export const FontStyles = createGlobalStyle`
    @font-face {
    font-family: 'Montserrat';
    src: 'https://fonts.googleapis.com/css?family=Montserrat';
    }
`;

export const theme = {
  color: {
    black: '#000000',
    white: '#FFFFFF',

    blueDark: '#171A21',
    blue: '#5190CE', // old '#6998C8',

    blueLight: '#D9FFFF',

    grey: '#464D4E',
    greyLight: '#aaaaaa',

    green: '#A0CA92',
    greenLight: '#D8F793',
  },
};
