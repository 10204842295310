import React, { useState } from 'react';

import styled, { css, ThemeProvider } from 'styled-components';
import { FontStyles, theme as defaultTheme } from '../../design/theme';
import { seed } from '../../content/seed';
import Footer from '../Footer';
import Menu from '../../components/Menu';

const AppContainer = styled.div<{ isMainPage?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ isMainPage }) =>
    !isMainPage &&
    css`
      min-height: 100vh;
    `}
`;

interface Props {
  isMainPage?: boolean;
}

const SiteTemplate: React.FC<Props> = ({ children, isMainPage }) => {
  const [theme] = useState(defaultTheme);

  return (
    <ThemeProvider theme={theme}>
      <FontStyles />
      <AppContainer isMainPage={isMainPage}>
        <Menu forced={!isMainPage} />

        {children}

        <Footer
          tax={seed.footer.tax}
          company={seed.footer.company}
          author={seed.footer.author}
        />
      </AppContainer>
    </ThemeProvider>
  );
};

SiteTemplate.defaultProps = {
  isMainPage: false,
};

export default SiteTemplate;
