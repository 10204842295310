import React from 'react';
import styled, { CSSProperties, keyframes } from 'styled-components';

export const H1 = styled.h1`
  font-family: Montserrat, sans-serif;
  font-size: 4em;
  text-align: center;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);

  text-transform: uppercase;

  color: ${(props) => props.theme.color.white};
`;

export const H2 = styled.h2`
  font-family: Montserrat, sans-serif;
  font-size: 3em;
  text-align: center;
  margin: 1rem;

  color: ${(props) => props.theme.color.white};

  @media only screen and (max-width: 800px) {
    font-size: 2em;
  }
`;

export const H3 = styled.h3`
  font-family: Montserrat, sans-serif;
  font-size: 2em;

  color: ${(props) => props.theme.color.grey};

  @media only screen and (max-width: 800px) {
    font-size: 1.7em;
  }
`;

export const Text = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 1.4;

  margin: 0;

  color: ${(props) => props.theme.color.black};
`;

export const TextSmall = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.2;

  margin: 0;

  color: ${(props) => props.theme.color.black};
`;

export const FooterText = styled(TextSmall)`
  color: ${(props) => props.theme.color.white};
`;

export const Link = styled.a`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 1.4;
  font-weight: bold;
  text-decoration-thickness: 2px;

  color: ${(props) => props.theme.color.black};
`;

interface LinkProps {
  value: string;
  style?: CSSProperties;
}

export const Phone = ({ value, style }: LinkProps) => (
  <Link href={`tel:${value}`} style={style}>
    {value}
  </Link>
);

export const Mail = ({ value, style }: LinkProps) => (
  <Link href={`mailto:${value}`} style={style}>
    {value}
  </Link>
);

export const ButtonBase = styled.button`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 1.4;

  margin: 0;
  padding: 5px;

  border: none;
  background-color: transparent;

  border-radius: 10px;

  transition: color 0.15s linear, background-color 0.15s linear;

  color: ${(props) => props.theme.color.black};
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonPrimary = styled(ButtonBase)`
  border: 2px solid ${(props) => props.theme.color.blue};

  background-color: ${(props) => props.theme.color.blue};
  color: ${(props) => props.theme.color.white};

  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.color.blue};
  }
`;

export const ButtonSecondary = styled(ButtonBase)`
  border: 2px solid ${(props) => props.theme.color.grey};

  background-color: transparent;
  color: ${(props) => props.theme.color.grey};

  &:hover {
    background-color: ${(props) => props.theme.color.grey};
    color: ${(props) => props.theme.color.white};
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const HamburgerSc = styled.div`
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

const HamburgerLineSc = styled.div`
  width: 25px;
  height: 3px;
  margin: 2px;

  border-radius: 10px;

  box-shadow: 2px 2px 4px rgb(0 0 0 / 30%);

  background: ${(props) => props.theme.color.white};
`;

export const Hamburger = () => (
  <HamburgerSc>
    <HamburgerLineSc />
    <HamburgerLineSc />
    <HamburgerLineSc />
  </HamburgerSc>
);
