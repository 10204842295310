import React, { useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { fadeOut, fadeIn, Hamburger } from '../design/SharedComponents';

const MENU_HEIGHT = '4rem';
const MENU_HEIGHT_SM = '3rem';
interface MenuSCProps {
  readonly expanded: boolean;
}

const MenuContainer = styled.nav<MenuSCProps>`
  background-color: ${(props) => props.theme.color.blue};
  height: ${MENU_HEIGHT};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  margin: 0;
  padding: 0;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;

  box-shadow: 0px 5px 15px #0000002b;

  list-style-type: none;

  transition: transform 0.4s ease-out;

  @media only screen and (max-width: 800px) {
    height: ${MENU_HEIGHT_SM};
  }

  @media only screen and (max-width: 600px) {
    transform: translateY(calc(-${MENU_HEIGHT} - 15px));

    ${(props) =>
      props.expanded &&
      css`
        transform: translateY(0);
      `};
  }
`;

const MenuItems = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  padding: 0;

  list-style-type: none;
`;

const Logo = styled.a`
  font-family: Montserrat, sans-serif;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
  text-transform: uppercase;
  font-size: 32px;
  margin: 0;
  margin-left: 2rem;

  font-weight: bold;

  padding: 0.3rem 1rem;

  text-decoration: none;

  color: ${(props) => props.theme.color.white};

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const Link = styled.a`
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 1.4;

  height: ${MENU_HEIGHT_SM};
  padding: 0 1rem;

  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.color.white};

  @media only screen and (max-width: 800px) {
    padding: 0 0.3rem;
  }
`;

interface ContainerProps {
  readonly forced: boolean;
}

const BackgroundContainer = styled.div<ContainerProps>`
  width: 100%;
  height: ${MENU_HEIGHT};
  min-height: ${MENU_HEIGHT};

  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 800px) {
    height: ${MENU_HEIGHT_SM};
    min-height: ${MENU_HEIGHT_SM};
  }

  @media only screen and (max-width: 600px) {
    ${({ forced }) =>
      !forced &&
      css`
        z-index: 1;
        top: 0;
        position: fixed;
      `};
  }
`;

interface MenuBtnProps {
  readonly hidden: boolean;
}

const MenuBtn = styled.button<MenuBtnProps>`
  font-family: Montserrat, sans-serif;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
  text-transform: uppercase;
  font-size: 24px;
  margin: 0;
  margin-right: 1.5rem;

  border: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.color.white};

  &:hover {
    cursor: pointer;
  }

  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
  animation: ${(props) => (props.hidden ? fadeOut : fadeIn)} 0.2s linear;
  transition: visibility 0.5s linear;

  @media only screen and (max-width: 600px) {
    margin-right: 0.5rem;
  }
`;

// only show it on bigger screens
const QualityLink = styled(Link)`
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

interface Props {
  forced?: boolean;
}

const Menu = ({ forced }: Props) => {
  const [visible, setVisible] = React.useState(forced || false);
  const eventListeners = useRef<() => void>();

  const scrollHandler = useCallback(() => {
    if (forced) return;
    setVisible(window.pageYOffset >= 1);
  }, []);

  useEffect(() => {
    eventListeners.current = scrollHandler;
    window.addEventListener('scroll', eventListeners.current, true);

    return () =>
      window.removeEventListener('scroll', eventListeners.current, true);
  }, [scrollHandler]);

  return (
    <BackgroundContainer forced={forced}>
      <MenuContainer expanded={visible}>
        <Logo href="/#home">UNIKOVO</Logo>

        <MenuItems role="menubar">
          <Link href="/#home" role="menuitem">
            Domov
          </Link>
          <Link href="/produkty" role="menuitem">
            Ponuka
          </Link>
          <Link href="/sluzby" role="menuitem">
            Služby
          </Link>
          <Link href="/#about" role="menuitem">
            O nás
          </Link>
          <QualityLink href="/#quality" role="menuitem">
            Kvalita
          </QualityLink>
          <Link href="/#contact" role="menuitem">
            Kontakt
          </Link>
        </MenuItems>
      </MenuContainer>
      <MenuBtn hidden={visible} onClick={() => setVisible(true)}>
        Menu
        <Hamburger />
      </MenuBtn>
    </BackgroundContainer>
  );
};

export default Menu;
