import { Seed } from '.';

export const seed: Seed = {
  meta: {
    title: 'Unikovo s.r.o.',
    description:
      'Neviete si poradiť? Unikovo Vám ochotne pomôže s návrhom a realizáciou vaších snov z oblasti zámočníctva.',
    keywords:
      'jig, jigy, jigs, stavebne zamocnictvo, schody, schodistia, ohybanie, zvaranie, bratislava, 3d, 3d navrh, laser, zamocnictvo, zamocnictvo bratislava, zamocnictvo kontakt',
  },
  title: {
    title: 'Unikovo',
    img: '',
  },
  contact: {
    heading: 'Unikovo s.r.o.',
    phone: '+421 905 560 467',
    email: 'unikovo@unikovo.sk',
    addressLine1: 'Horská 30',
    addressLine2: '831 02, Bratislava',
  },
  footer: {
    tax: ['IČO: 47040050', 'DIČ: 2023730357', 'IČ DPH: SK2023730357'],
    company: 'Unikovo s.r.o.',
    author: 'Peter Stríž',
  },
  sectionHeadings: {
    articles: 'Moderné technológie, tradičné spôsoby',
    about: 'O nás',
    testament: 'Garancia kvality',
    contact: 'Kontakt',
  },
  about: {
    paragraphs: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utet dolore magnnim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    ],
  },
  articles: [
    {
      title: 'Výroba vešiakov - jigov',
      text: 'Ponúkame výrobu kovových vešiakov do lakovni určené na vešanie plastových výrobkov pre automatické a robotické lakovanie. S výrobou vešiakov na lakovanie nárazníkov áut máme bohaté skúsenosti od roku 2002. Štandardom je u nás vypracovať návrhy vešiaku na základe dodaných modelov dielov v systémoch CAD/CAM, SolidWorks a pod.',
      // text: 'Ponúkame výrobu vešiakov na vešanie výrobkov hlavne z plastu do lakovní, kde používajú automatické, robotické lakovanie. Máme skúsenosti s návrhom vešiakov na lakovanie nárazníkov áut. Sme schopní vypracovať návrhy tvaru a umiestnenia vešiaku v striekacom okne linky na lakovanie na základe dodaných výkresov v systémoch CAD/CAM a SolidWorks.',
      img: 'unikovo/main/article/accessories-bag_pktvp3',
    },
    {
      title: 'Kreslenie v 3D programoch',
      text: 'Ponúkame možnosť vypracovania 3D modelu výrobku. Pri bežných výrobkoch to uľahčuje predstavivosť ako bude výrobok vyzerať a pri zložitejších výrobkoch to je nevyhnutnosťou.',
      img: 'unikovo/main/article/car-interior-design_niwx5h',
    },
    {
      title: 'Najmodernejšie stroje',
      text: 'Naša firma disponuje tými najmodernejšími technológiami. Ponúkame rezanie ocele do 12mm, nerezu do 8mm hrúbky plechu, max. rozmer plechu 1,5x3m.',
      img: 'unikovo/main/article/accessories-bag_pktvp3',
    },
    {
      title: 'Dôraz na detaily',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      img: 'unikovo/main/article/leather-bag-gray_m10gub',
    },
    {
      title: 'Stavebné zámočníctvo',
      text: 'Ponúkame výrobu a montáž rôznych kovových výrobkov. Automatické posuvné plotove brány, zábradia, atypické poklopy, konzoly, rámy, nosníky, stĺpy, oceľové konštrukcie, vonkajšie krby, stožiare, oceľové schodištia.',
      img: 'unikovo/main/article/shoes_zfxunq',
    },
  ],
};
